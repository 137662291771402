#root {
    position: relative;
    max-width: 100vw;
    /* height: auto; */
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

html, body {
    flex: 1;
    /* font-size: 15px; */
    display: flex;
    flex-direction: column;
    min-height: 100%;
    -webkit-tap-highlight-color: transparent;
}

.aspectRatio1 {
    aspect-ratio: '1 / 1'
}

@supports not (aspect-ratio: 1 / 1) {
    .aspectRatio1::before {
      float: left;
      padding-top: 100%;
      content: "";
    }
  
    .aspectRatio1::after {
      display: block;
      content: "";
      clear: both;
    }
}

@media print {
    html, body {
        font-size: 17px;
        height:100vh;
        width: 400mm;
        padding: 0 !important;
        margin: 0 auto !important;
    }

    #root {
        /* Very important so that Puppeteer can render the whole page */
        overflow: unset; 
        /* width: 100%; */
    }

    #root > div {
        max-width: 100%;
    }
    
    @page {
        size: 460mm 684mm;
        /* size: 460mm 670mm; This is the best, but has the third page */
        margin: 0 auto;
    }
}